<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config.json";
import { authComputed } from "@/state/helpers";
import { required } from "vuelidate/lib/validators";
import store from "@/state/store";
export default {
  beforeRouteEnter(to, from, next) {
    const userType = store.getters["auth/getUser"].user_type;
    if (userType === 1 || userType === 3) {
      next();
    } else {
      alert("You are not allowed to access this page. You will be redirected.");
      next("/");
    }
  },
  page: {
    title: "Employee Leave Type Accrual Details",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    ...authComputed,
  },
  components: {
    Layout,
    PageHeader,
  },
  validations: {
    noOfDays: { required },
    narration: { required },
    leaveType: { required },
  },
  mounted() {
    /*params: {
      leaveTypeId: leaveTypeId,
        year:this.$route.params.year,
        empId: this.$route.params.empId
    },*/
    this.refreshTable();
  },
  methods: {
    refreshTable() {
      let year = this.$route.params.year;
      let empId = this.$route.params.empId;
      let leaveTypeId = this.$route.params.leaveTypeId;

      const url = `${this.ROUTES.leaveAccrual}/leave-type/${leaveTypeId}/${year}/${empId}`;
      this.apiGet(url, "Get Leave Accrual Error").then((res) => {
        this.result = true;
        const { data } = res;
        this.employee = data.employee;
        this.leaveName = data.leaveType.leave_name;
        //console.log(data)
        this.totalAccrued = data.employeeLeaveObject.totalAccrued;
        this.totalTaken = data.employeeLeaveObject.totalTaken;
        this.balance = data.employeeLeaveObject.totalAccrued;
        let empAccrual = data.accrual;
        empAccrual.map((val, index)=>{
          let narration = '';
          if(val.leave_narration === '' || val.leave_narration === null){
            if(parseInt(val.lea_rate) < 0 ){
              narration = 'Used';
            }else{
              narration = 'Accrued';
            }
          }else{
            narration = val.leave_narration;
          }
          //console.log(parseInt(val.lea_rate) < 0 ? val.lea_rate : 0)
          let cont = {
            sn:index+1,
            debit: parseInt(val.lea_rate) < 0 ? val.lea_rate : 0 ,
            credit:parseInt(val.lea_rate) > 0 ? val.lea_rate : 0 ,
            month:val.lea_month || '',
            year:val.lea_year || '',
            narration: narration || '',
          };
          this.accruals.push(cont);
        })
        //console.log(data);
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectRow(row) {
      row = row[0];
      this.leaveAppID = row.leapp_id;
      this.$router.push({
        name: "leave-accrual-leave-type-details",
        params: { leaveTypeId: this.leaveTypeId },
      });
    },
    submitNewLeaveDays(){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.apiFormHandler("Invalid Leave Days entry");
      } else {
        const data = {
          leaveType: parseInt(this.leaveType),
          noOfDays: parseInt(this.noOfDays),
          narration: this.narration,
          empId: parseInt(this.employee.emp_id),
          expiresOn:this.expiresOn
        };
        const url = `${this.ROUTES.leaveAccrual}/add-accruals`;
        this.apiPost(url, data, "Add Leave Days Error").then((res) => {
          this.apiResponseHandler(`${res.data}`, "New Leave Days Added");
          location.reload();
          this.refreshTable();
          this.$v.$reset();
          this.$refs["add-leave-days"].hide();
        });
      }
    },
    handleChange(e){
      //if(e.target.options.selectedIndex > -1) {
        console.log(e.target.value)
     // }
    },
    resetForm(){
      this.leaveType = null;
      this.noOfDays = null;
      this.narration = null;
      this.$v.$reset();
    }
  },
  data() {
    return {
      title: " Employee Leave Type Accrual Details",
      items: [
        {
          text: "IHUMANE",
        },
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: " Employee Leave Accrual Details",
          active: true,
        },
      ],
      leaveName:null,
      leave_types: [],
      emp_leave_types: [],
      employee:{},
      accruals: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "sn",
      sortDesc: false,
      newFields: [],
      used:0,
      left:0,
      archived:0,
      totalAccrual:0,
      totalAccrued:0,
      totalTaken:0,
      balance:0,
      expiresOn:null,
      noOfDays:0,
      narration:null,
      leaveType:null,
      fields: [
        { key: "sn", label: "S/n", sortable: true },
        //{ key: "leaveType", label: "Leave Type", sortable: true },
        { key: "debit", label: "Debit", sortable: true },
        { key: "credit", label: "Credit", sortable: true },
        { key: "month", label: "Month", sortable: true },
        { key: "year", label: "Year", sortable: true },
        { key: "narration", label: "Narration", sortable: true },
      ],
      leaveAppID: null,
      year: 2022,
      result: false,
      submitted: false,
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <scale-loader v-if="apiBusy" />
    <div v-else class="row">
      <div class="col-12" v-if="result">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6"
              ><div class="card">
                <div class="row align-items-center no-gutters">
                  <div class="col-md-4">
                    <img v-bind:src="employee.emp_avatar || '' " class="rounded-0 card-img">
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h4 class="card-title">{{ employee.emp_first_name || '' }} {{ employee.emp_last_name || '' }} {{ employee.emp_other_name  || ''}}</h4>
                  <p class="card-text"><strong>T7: </strong> {{ employee.emp_d7 || '-' }} <br>
                    <strong style="font-weight: 700;">D6: </strong>{{ employee.location.location_name || '' }} <br>
                    <strong style="font-weight: 700;">T3: </strong>{{ employee.sector.department_name || '' }} <br>
                    <strong style="font-weight: 700;">Fiscal Year: </strong>{{ this.$route.params.year || '' }} <br>
                    <strong style="font-weight: 700;">Leave Type: </strong>{{ leaveName || '' }} <br>
                  </p>
              </div>
              </div>
              </div>
              </div>
              </div>
              <div class="col-lg-6">
                <div class="">
                  <div class="">
                    <button type="button" @click="$refs['add-leave-days'].show()" class="btn btn-primary float-right"> <i class="mdi mdi-plus"></i> Add Leave Days</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <h5 class="text-uppercase">Leave Accrual Summary <small>({{ leaveName }})</small></h5>
                <table class="table table-bordered">
                  <tr>
                    <th>Total Accrued</th>
                    <th>Total Taken</th>
                    <th>Balance</th>
                  </tr>
                  <tr>
                    <td>
                      {{ totalAccrued }}
                    </td>
                    <td>
                      {{ totalTaken }}
                    </td>
                    <td>
                      {{ Math.abs(totalAccrued) - Math.abs(totalTaken) }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                ref="donor-table"
                bordered
                selectable
                hover
                :items="accruals"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                show-empty
                select-mode="single"

              >
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      ref="add-leave-days"
      title="Add Leave Days"
      hide-footer
      centered
      title-class="font-18"
      @hidden="resetForm"
    >
      <form @submit.prevent="submitNewLeaveDays">
        <div class="form-group">
          <label for="name">
            Leave Type <span class="text-danger">*</span>
          </label>
          <select @change="handleChange" v-model="leaveType" class="form-control"
                  :class="{
              'is-invalid': submitted && $v.leaveType.$error,
            }">
            <option v-for="(item,index) in leave_types" :key="index" :value="item.leave_type_id">
              {{ item.leave_name || '' }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="bank_code">
            Number of Days <span class="text-danger">*</span>
          </label>
          <input
            id="noOfDays"
            type="number"
            v-model="noOfDays"
            class="form-control"
            :class="{
              'is-invalid': submitted && $v.noOfDays.$error,
            }"
          />
        </div>
        <div class="form-group">
          <label for="bank_code">
            Expires on <span class="text-danger">*</span>
          </label>
          <input
            id="expiresOn"
            type="date"
            v-model="expiresOn"
            class="form-control"
            :class="{
              'is-invalid': submitted && $v.expiresOn.$error,
            }"
          />
        </div>
        <div class="form-group">
          <label for="">Narration <span class="text-danger">*</span></label>
          <textarea v-model="narration" style="resize: none;" placeholder="Enter a narration here..." class="form-control">

          </textarea>
        </div>
        <b-button
          v-if="!submitting"
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submit
        </b-button>
        <b-button
          v-else
          disabled
          class="btn btn-success btn-block mt-4"
          type="submit"
        >
          Submitting...
        </b-button>
      </form>
    </b-modal>
  </Layout>
</template>
